/* eslint-disable max-len */
import React from "react";

export const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_375_3356)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C24.8366 0 32 7.16345 32 16C32 24.8366 24.8366 32 16 32C7.16345 32 0 24.8366 0 16C0 7.16345 7.16345 0 16 0ZM19.6202 8.72727H17.2531C14.4093 8.72727 13.4089 10.1261 13.4089 12.4773V14.2077H11.6364V17.0898H13.4089V25.4545H16.9556V17.0898H19.3226L19.6364 14.2077H16.9556L16.9605 12.765C16.9605 12.0133 17.0336 11.6106 18.1407 11.6106H19.6202V8.72727Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_375_3356">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
