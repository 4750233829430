import "react-app-polyfill/ie11";

import * as React from "react";
import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import { i18nConfig } from "../../i18nConfig";

import { CookiePreferences } from "../../components/cookie-dialog/CookiePreferences";
import { App } from "../../components/app";

import "../../styles/index.css";

const cookieSelector = (window as any).TrimbleCookiesSelector
  ? (window as any).TrimbleCookiesSelector
  : "#trimble-cookies-trigger";

const footerSelector = (window as any).TeklaUniversalFooter
  ? (window as any).TeklaUniversalFooter
  : "#tekla-common-footer";

const cookieElement = document.querySelector(cookieSelector);
const footerElement = document.querySelector(footerSelector);

function getLanguage(element): string {
  const language = element.getAttribute("data-language");
  if (language) {
    return language.toLowerCase().replace("_", "-");
  } else {
    return "en";
  }
}

if (!!cookieElement) {
  const cookieRoot = createRoot(cookieElement!);

  cookieRoot.render(
    <IntlProvider
      locale={getLanguage(cookieElement)}
      defaultLocale={i18nConfig.defaultLocale}
      messages={i18nConfig.messages[getLanguage(cookieElement)]}
      onError={() => {
        return;
      }}
    >
      <CookiePreferences canUseSiteBeforeAction={true} />
    </IntlProvider>,
  );
}

if (!!footerElement) {
  const footerRoot = createRoot(footerElement!);

  footerRoot.render(
    <IntlProvider
      locale={getLanguage(footerElement)}
      defaultLocale={i18nConfig.defaultLocale}
      messages={i18nConfig.messages[getLanguage(footerElement)]}
      onError={() => {
        return;
      }}
    >
      <App canUseSiteBeforeAction={true} />
    </IntlProvider>,
  );
}
