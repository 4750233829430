/* eslint-disable max-len */
import React from "react";

export const YoutubeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_375_3365)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C24.8366 0 32 7.16345 32 16C32 24.8366 24.8366 32 16 32C7.16345 32 0 24.8366 0 16C0 7.16345 7.16345 0 16 0ZM20.7661 10.9091L11.2031 10.9098C10.9201 10.9195 8.72727 11.0703 8.72727 13.4079V18.5921C8.72727 18.5921 8.72727 21.0909 11.2331 21.0909L20.7961 21.0902C21.0791 21.0805 23.2727 20.9297 23.2727 18.5921V13.4079C23.2727 13.4079 23.2727 10.9091 20.7661 10.9091ZM14.0621 13.2186L18.8239 16.0073L14.0621 18.795V13.2186Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_375_3365">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
