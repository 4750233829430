/* eslint-disable max-len */
import React from "react";

export const TwitterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_607_331)">
      <path d="M9.46655 8.7998L20.1332 23.1998H22.6666L11.8666 8.7998H9.46655Z" fill="white" />
      <path
        d="M16 0C7.2 0 0 7.2 0 16C0 24.8 7.2 32 16 32C24.8 32 32 24.8 32 16C32 7.2 24.8 0 16 0ZM19.6 24.4L14.6667 17.7333L8.53333 24.2667H7.06667L14 16.8L7.06667 7.6H12.4L16.9333 13.6L22.5333 7.6H24.1333L17.6 14.6667L24.9333 24.4H19.6Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_607_331">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
