import React, { Fragment } from "react";
import { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

import { CookiesSettingsDialogContext, CookiesSettingsDialogStore } from "./cookiesSettingsDialogStore";
import { i18nConfig } from "../../i18nConfig";
import { PreferenceGroups } from "./PreferenceGroups";

const PreferenceViewDescription = observer(() => {
  const dialog: CookiesSettingsDialogStore = useContext(CookiesSettingsDialogContext);

  const preferencesText = dialog.areCookiesAcceptedByDefault()
    ? "preferencesText.part2.optIn"
    : "preferencesText.part2.optOut";

  return (
    <section data-testid="cookiePreferencesView">
      <div className="cookies-text-content">
        <p className="cookies-general-information" data-testid="cookiePreferenceDescription">
          <FormattedMessage
            id={"preferencesText.part1"}
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["preferencesText.part1"]}
          />{" "}
          <FormattedMessage
            id={preferencesText}
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale][preferencesText]}
          />
        </p>
        <p className="cookies-general-information">
          <FormattedMessage
            id={"preferencesText.part3"}
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["preferencesText.part3"]}
          />
        </p>
        <p>
          <FormattedMessage
            id="cookieNotice"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["cookieNotice"]}
            values={{
              cookieNoticeLink: (
                <a href="https://www.tekla.com/tekla-cookie-notice" rel="noopener noreferrer" target="_blank">
                  <FormattedMessage
                    id="cookieNoticeLinkText"
                    defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["cookieNoticeLinkText"]}
                  />
                </a>
              ),
            }}
          />
        </p>
      </div>
    </section>
  );
})

/**
 * Component that renders the 'Edit preferences' view of the dialog.
 */
export const EditPreferencesView = observer(() => {
  const store: CookiesSettingsDialogStore = useContext(CookiesSettingsDialogContext);

  useEffect(() => {
    store.init();
  }, [store]);

  function handleCloseDialog() {
    store.saveCookiePreferences();

    store.closeDialog();
    window.location.reload();
  }

  return (
    <Fragment>
      <header className="cookies-dialog-header" data-testid="cookieDialogHeader">
        <h3>
          <FormattedMessage
            id="cookiePreferences"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["cookiePreferences"]}
          />
        </h3>
      </header>
      <PreferenceViewDescription />
      <PreferenceGroups />
      <footer>
        <div className="cookies-actions">
          <div className="secondary-action">
            <button className="cookies-button secondary" onClick={() => store.returnToLanding()}>
              <FormattedMessage
                id="actions.cancel"
                defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["actions.cancel"]}
              />
            </button>
          </div>
          <div className="primary-action">
            <button className="cookies-button primary" onClick={handleCloseDialog}>
              <FormattedMessage
                id="actions.save"
                defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["actions.save"]}
              />
            </button>
          </div>
        </div>
      </footer>
    </Fragment>
  );
});
